<template>
    <div>
        <LiefengContent :isBack="route.isBack()" @backClick="route.back()">
            <template v-slot:title>{{ "社区组织动员体系数据导入" }}</template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true">
                    <FormItem>
                        <LiefengCascaderCity :width="200" @changeCasader="changeCasader" :isClear="true" :CascaderList="search.cascaderIdList" orgCode="4401"></LiefengCascaderCity>
                    </FormItem>
                    <FormItem>
                        <Select placeholder="请选择导入类型" transfer v-model="search.importType" style="width:200px">
                            <Option v-for="(item, index) in typeList" :value="item.value" :key="index">{{ item.label }}</Option>
                        </Select>
                    </FormItem>
                    <Button type="primary" style="margin-right:10px" @click="searchBtn">查询</Button>

                    <!-- <Dropdown> -->
                        <Button type="primary" @click="openModal(4)" style="margin-right: 10px">
                            导入社区居住人员名册
                        </Button>
                        <!-- <DropdownMenu slot="list">
                            <DropdownItem>
                                <span @click="openModal(4)">
                                    新模板
                                </span>
                            </DropdownItem>
                            <DropdownItem>
                                <span @click="openModal(1)">
                                    旧模板
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown> -->
                    <Button type="info" style="margin-right:10px" @click="openModal(2)">导入网格党支部管理</Button>
                    <Button type="info" @click="openModal(3)">导入网格(楼栋)党小组管理</Button>
                    <!-- <Button type="info" @click="openModal(4)">导入新社区居住人员名册</Button>  -->
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable :talbeColumns="table.talbeColumns" :tableData="table.tableData" :loading="table.loading" :fixTable="true" :curPage="table.page" :total="table.total" :pagesizeOpts="[20, 30, 50, 100]" :page-size="table.pageSize" @hadlePageSize="hadlePageSize"></LiefengTable>
                <!-- 导入表格 -->
                <LiefengModal :title="modal.title" width="40%" height="260px" :value="modal.excelStatus" @input="excelModalData" class="export-modal">
                    <template v-slot:contentarea v-if="modal.modalStatus">
                        <Form :label-width="100">
                            <div style="display:flex">
                                <span style="padding-top: 5px;margin-right: 5px;" class="validate">选择社区</span>
                                <LiefengCascaderCity :resetNum="modal.resetNum" :CascaderList="modal.orgCodeList" :orgCode="4401" @changeCasader="changeExcelCasader"></LiefengCascaderCity>
                            </div>
                        </Form>
                        <LiefengUploadExcel style="margin:20px 0" :showDownModal="modal.showModal" :headers="modal.headers" :action="modal.action" :uploadData="modal.uploadData" :isShow="modal.showExcel" @success="excelSuccess"></LiefengUploadExcel>
                    </template>
                </LiefengModal>

                <!-- 查看明细 -->
                <LiefengModal :fullscreen="true" :title="tableModal.title" :value="tableModal.tableStatus" @input="tableModalData">
                    <template v-slot:contentarea v-if="tableModal.modalStatus">
                        <LiefengTable
                            :talbeColumns="tableModal.talbeColumns"
                            :tableData="tableModal.tableData"
                            :loading="tableModal.loading"
                            :fixTable="true"
                            :curPage="tableModal.page"
                            :total="tableModal.total"
                            :pagesizeOpts="[20, 30, 50, 100]"
                            :page-size="tableModal.pageSize"
                            @hadlePageSize="tableHadlePageSize"
                        ></LiefengTable>
                    </template>
                </LiefengModal>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import LiefengUploadExcel from "./childrens/LiefengUploadExcel.vue"
import { tableMixin } from "./tableMixin"
import Route from "../communitymobilization/entity/Route"
export default {
    components: { LiefengContent, LiefengTable, LiefengModal, LiefengCascaderCity, LiefengUploadExcel },
    mixins: [tableMixin],
    data() {
        return {
            route: Route,
            search: {
                cascaderIdList: [],
                type: "",
            },
        }
    },
    async created() {
        await this.getList()
    },
    methods: {
        // 方法部分
        // 切换分页事件
        hadlePageSize(val) {
            this.table.page = val.page
            this.table.pageSize = val.pageSize
            this.getList()
        },

        // 查询按钮
        searchBtn() {
            console.log(this.search.cascaderIdList)
            this.table.page = 1
            this.table.pageSize = 20
            this.getList()
        },

        // 选择社区事件
        changeCasader(val) {
            this.search.cascaderIdList = val
        },

        changeExcelCasader(val) {
            if (val && val.length == 3) {
                this.modal.uploadData.orgCode = val[val.length - 1]
            } else {
                this.modal.uploadData.orgCode = ""
            }
        },
        // 关闭弹窗事件
        excelModalData(status) {
            this.modal.excelStatus = status
            if (!status) {
                this.modal.modalStatus = false
            }
        },

        // 打开弹窗事件
        openModal(status) {
            this.modal = {
                excelStatus: true,
                modalStatus: true,
                uploadData: {
                    orgCode: "",
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    oemCode: parent.vue.oemInfo.oemCode,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                },
                orgCodeList: [],
                headers: {
                    Authorization: window.sessionStorage.getItem("accessToken"),
                },
                resetNum: 0,
            }
            this.showExcel++
            // this.modal.resetNum++
            if (status == 1) {
                this.modal.title = "导入社区居住人员名册"
                this.modal.action = window.vue.getProxy()["/syaa"].target + "/upload/sy/user/gridManage/importUser"
                this.modal.showModal = { isShow: true, downLink: "./excelfiles/userImport.xls", downName: "导入社区居住人员名册" }
            } else if (status == 2) {
                this.modal.title = "导入网格党支部管理"
                this.modal.action = window.vue.getProxy()["/syaa"].target + "/upload/sy/user/gridManage/importPartyBranch"
                this.modal.showModal = { isShow: true, downLink: "./excelfiles/partyImport.xls", downName: "导入网格党支部管理" }
            } else if (status == 3) {
                this.modal.title = "导入网格(楼栋)党小组管理"
                this.modal.action = window.vue.getProxy()["/syaa"].target + "/upload/sy/user/gridManage/importGroup"
                this.modal.showModal = { isShow: true, downLink: "./excelfiles/gridImport.xls", downName: "导入网格(楼栋)党小组管理" }
            } else if (status == 4) {
                this.modal.title = "导入社区居住人员名册"
                this.modal.action = window.vue.getProxy()["/syaa"].target + "/upload/sy/user/gridManage/importUserV2"
                this.modal.showModal = { isShow: true, downLink: "./excelfiles/gridImportNew.xls", downName: "导入社区居住人员名册新模板" }
            }
        },

        excelSuccess() {
            this.modal.excelStatus = false
            this.table.page = 1
            this.table.pageSize = 20
            this.getList()
        },
    },
}
</script>

<style lang="less" scoped>
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
</style>
